<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增班级</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="Form" :model="Form" :rules="rules" label-width="130px">
              <h3 class="textBt">基本信息</h3>
              <el-form-item label="班级名称" prop="projectName" class="form-item">
                <el-input v-model="Form.projectName" placeholder="请输入班级名称" maxlength="50"
                  show-word-limit size="small" />
              </el-form-item>
              <el-form-item label="行政区划" prop="areaId" class="form-item">
                <el-cascader v-model="Form.areaId" clearable filterable :options="areatreeList" :props="propsarea"
                  @change="areaChange" size="small"></el-cascader>
              </el-form-item>
              <el-form-item label="有效日期" prop="Effectivedate" class="form-item">
                <el-date-picker size="small" v-model="Form.Effectivedate" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
              </el-form-item>
              <el-form-item label="报名截止时间" prop="deadline" class="form-item">
                <el-date-picker size="small" v-model="Form.deadline" type="date" format="yyyy-MM-dd"
                  value-format="timestamp" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="负责人" prop="projectUser" class="form-item">
                <el-input v-model="Form.projectUser" placeholder="请输入负责人" size="small" />
              </el-form-item>
              <el-form-item label="负责人电话" prop="projectUserPhone" class="form-item">
                <el-input v-model="Form.projectUserPhone" placeholder="请输入负责人电话" maxlength="12"
                  size="small" />
              </el-form-item>
              <el-form-item label="培训类型" prop="Trainingtype" class="form-item">
                <tree ref="tree" @eventBtn="childBack" :ruleForm="Form" size="small" @clearParams="clearParams" modal
                  type typeStu />
              </el-form-item>
              <el-form-item label="培训人数" prop="projectPeople" class="form-item" v-if="params.trainFormIdPath" required>
                <el-input-number v-model="Form.projectPeople" @change="handleChange" :min="1" style="width: 100%"
                  size="small"></el-input-number>
              </el-form-item>
              <el-form-item label="期次" prop="projectPc" class="form-item" v-if="params.trainFormIdPath">
                <el-input type="text" v-model="Form.projectPc" size="small"></el-input>
              </el-form-item>
              <el-form-item label="人群类型" prop="projectCrowd" class="form-item" v-if="params.trainFormIdPath == '4'">
                <el-select v-model="Form.projectCrowd" placeholder="请选择" size="small">
                  <el-option v-for="item in CrowdtypeList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="付款形式" prop="paymentMode" class="form-item">
                <el-select v-model="Form.paymentMode" placeholder="请选择" size="small">
                  <el-option v-for="item in paymentModeList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="第三方预付机构" prop="paymentCompId" class="form-item" v-if="Form.paymentMode == 20">
                <el-select size="small" v-model="Form.paymentCompId" remote :remote-method="getCompanyList" filterable
                  clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                  <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                    :value="item.compId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="启用新上传资料" label-width="200" prop="paraIsMaterial" class="form-item" v-if="Form.paymentMode == 20">
                <el-radio-group v-model="Form.paraIsMaterial">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="课程分成金额" prop="paymentCommission" class="form-item" v-if="Form.paymentMode == 20">
                <el-input-number class="input_1" v-model="Form.paymentCommission" :min="0"
                  :precision="2"></el-input-number>
              </el-form-item>
              <h3 class="textBt">基本设置</h3>
              <el-form-item cla label="允许学员查看学时证明" label-width="200" prop="projectClassHourCertificate"
                class="form-item">
                <el-radio-group v-model="Form.projectClassHourCertificate">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData()" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "addClassCtr",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的负责人电话"));
      } else {
        callback();
      }
    };

    return {
      areatreeList: [], // 行政区划 - 下拉数据
      // 区划 - 格式字段
      props: {
        value: "id",
        label: "label",
      },
      // 区划 - 字段配置
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      CrowdtypeList: [], // 人群类型 - 下拉数据
      paymentModeList:[], // 付款形式 - 下拉数据
      CompanyList: [], // 第三方预付机构 - 下拉数据
      Form: {
        projectName: "",// 班级名称
        areaId: "",// 行政区划
        Effectivedate: [],// 报名截止时间
        deadline: "",// 报名截止时间
        projectUser: "",// 负责人
        projectUserPhone: "",// 负责人电话
        projectPeople: "",//培训人数
        projectPc: "",// 期次
        projectCrowd: "",//人群类型
        Trainingtype: "", //培训类型
        paymentMode:"10", // 付款形式
        paymentCompId: "", // 第三方预付机构
        paraIsMaterial: false, // 启用新上传资料
        paymentCommission:"", // 课程分成金额
        projectClassHourCertificate: false, //是否允许打印学时证明
        trainMethod: "10", //培训方式默认线上
      },
      params: {}, // 培训类型数据集合
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        paymentMode: [
          { required: true, message: "请选择付款形式", trigger: "change" },
        ],
        projectClassHourCertificate: [
          { required: true, message: "允许学员查看学时证明", trigger: "change" },
        ],
        paymentCompId: [
          { required: true, message: "请选择第三方预付机构", trigger: "change" },
        ],
        paraIsMaterial: [
          { required: true, message: "请选择第三方预付机构", trigger: "change" },
        ],
        paymentCommission: [
          { required: true, message: "请输入课程分成金额", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getInvoice_type();
    this.getareatree();
  },
  methods: {
    // 获取字典
    getInvoice_type() {
      // 人群类型
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      for (const key in CrowdtypeList) {
        this.CrowdtypeList.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      // 付款形式
      const paymentModeList = this.$setDictionary("PROJECT_PAYMENT_MODE", "list");
      for (const key in paymentModeList) {
        this.paymentModeList.push({
          value: key,
          label: paymentModeList[key],
        });
      }
    },
    // 获取 - 行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 行政区划change事件
    areaChange(val) {
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
    // 培训类型 - 确定
    childBack(params) {
      this.params = { ...params };
    },
    // 培训类型 - 取消
    clearParams() {
      this.params = {};
    },
    // 获取机构 - 两个字查询
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 保存
    seveData() {
      this.$refs['Form'].validate((valid) => {
        if (valid) {
          let parmar = {
            projectName: this.Form.projectName, //班级名称
            areaId: this.Form.areaId, // 行政区划Id
            startDate: this.Form.Effectivedate[0], //有效开始日期
            endDate: this.Form.Effectivedate[1], // 有效结束日期
            signEndDate: this.Form.deadline, //报名截止日期
            projectUser: this.Form.projectUser, //负责人
            projectUserPhone: this.Form.projectUserPhone, //负责人电话
            projectPeople: this.Form.projectPeople, //培训人数
            paymentMode: this.Form.paymentMode, // 付款形式
            paymentCompId:  this.Form.paymentCompId, // 第三方预付机构
            paraIsMaterial: this.Form.paraIsMaterial, // 启用新上传资料
            paymentCommission: this.Form.paymentCommission, // 课程金额分成
            projectClassHourCertificate: this.Form.projectClassHourCertificate, //是否允许打印学时证明
            trainMethod: this.Form.trainMethod, //培训方式默认线上
          };
          if (this.Form.projectCrowd) {
            parmar.projectCrowd = this.Form.projectCrowd;
          }
          if (this.Form.projectPc) {
            parmar.projectPc = this.Form.projectPc;
          }
          if (this.params.trainFormId) {
            parmar.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            parmar.trainLevelId = this.params.levelFormId;
          }
          if (this.params.occFormId) {
            parmar.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            parmar.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            parmar.industryId = this.params.industryFormId;
          }
          if (this.$route.query.projectSource) {
            parmar.projectSource = this.$route.query.projectSource;
          }
          this.$post("/biz/project/hospital/save", parmar)
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: ret.message,
                  type: "success",
                });
                this.$router.push({
                  name: "classListBj",
                  params: {
                    refresh: true,
                    isAdd: true,
                    addId: ret.data, //新班级Id，日报做处理
                  },
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 取消
    cancle() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .textBt {
      width: 100%;
      margin-bottom: 20px;
    }

    .form-item {
      width: 45%;
    }

    .form-item_1 {
      width: 100%;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-demo {
    .el-upload {
      border: none !important;
      max-width: 5rem !important;
      height: 30px !important;
    }
  }
}
</style>